import React from "react";
import { publicUrlFor } from "../../../../globals/constants";

function PrereatmentPage() {
  return (
    <div
      className="section-full p-t40 p-b50"
      // style={{
      //   backgroundImage: `url(${publicUrlFor(
      //     "images/background/bg-map.png"
      //   )})`,
      // }}
    >
      <div className="container">
        {" "}
        <div className="my-3">
          <div className="">
            {/* <h5 className="card-title">Special title treatment</h5> */}
            {/* <div className="card my-3"> */}
            {/* <div className="card-body"> */}
            {/* <span className="text-info display-block title-second text-uppercase font-20 font-weight-600 display-block m-b5">
              <i> Parer Slimicides </i>
            </span> */}
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Ensuring that closed loop systems are well maintained and operate
              at optimum efficiency it is essential that the correct water
              conditions are always maintained.
            </p>
            <p
              className="fs-6 m-t30"
              style={{
                fontWeight: "400",
              }}
            >
              Our advanced technology closed cooling water system treatment and
              closed loop treatment chemicals have been scientifically
              formulated to deliver significant operational benefits and
              performance improvements.
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrereatmentPage;
