import FastImage from "../../../../globals/elements/fastimg";
import { route } from "../../../../globals/constants";
import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

function ContactUsPage() {
  const [formData, setFormData] = useState({
    companyName: "",
    dealingWith: "",
    email: "",
    contactNo: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://cometapi.agmetech.com/api/mail/contact-us",
        {
          CompanyName: formData.companyName,
          DealingWith: formData.dealingWith,
          email: formData.email,
          contactNo: formData.contactNo,
          Message: formData.message,
        }
      );
      alert("Contact information submitted successfully!");
      console.log(response.data);
      // Reset form fields
      setFormData({
        companyName: "",
        dealingWith: "",
        email: "",
        contactNo: "",
        message: "",
      });
    } catch (error) {
      alert("There was an error submitting the form.");
      console.error(error);
    }
  };
  return (
    <>
      <div
        className="section-full p-t60"
        //   style={{
        //   backgroundImage: `url(${publicUrlFor(
        //     "images/background/bg15.jpg"
        //   )})`,
        // }}
      >
        <div className="container">
          <div className="row m-t80 ">
            <div className="col-lg-6 col-md-6">
              {/* <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light"> */}
              {/* <div className="mt-thum-box mt-img mt-img-effect"> */}
              <FastImage
                src="media/bgimg/banner_contactus.jpg"
                width={500}
                height={15}
                alt="image"
              />
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <div className="row-container">
                    <h2 className="display-block fs-3 ">
                      Our goal is to deliver efficient and high-quality water
                      treatment solutions to all type of industry.
                    </h2>
                    <div className="mt-separator-outer m-b10">
                      <div className="mt-separator bg-warning  m-t15 m-b30" />
                    </div>
                  </div>
                  <p
                    className="m-b15"
                    style={{
                      fontWeight: "400",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    We believe in tailoring our solutions to meet the unique
                    needs of each customer. Our focus is on delivering
                    innovative and customized water treatment solutions.
                  </p>
                  <ul
                    className="list-check-circle warning m-t15"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li>Cutting-edge chemical technology</li>
                    <li>Thorough equipment functionality assessments</li>
                    <li>Pioneering manufacturing approaches</li>
                    <li>
                      Extensive experience in water treatment applications
                    </li>
                  </ul>

                  {/* <div className="text-center p-tb20">
                    <NavLink
                      className="mfp-link"
                      to={route.services.SERVICES}
                      // title="wish list"
                    >
                      <button
                        className="site-button icon-arrow bg-warning"
                        type="button"
                      >
                        Our Services&nbsp;&nbsp;&nbsp;
                        <b className="fa fa-arrow-right" />
                      </button>
                    </NavLink>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-lg-1 col-md-6">
            </div> */}
              {/* </div>{" "} */}
              {/* </div> */}
            </div>{" "}
            {/* <div className="row py-15">
            <div className="col-lg-6 pt-3 col-md-6 m-b50 bg-gray mt-product bdr-1 bdr-solid bdr-gray-light">
              <h4 className="site-text-primary">Phone number</h4>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 15,
                }}
              >
                +91-98240 40260
              </p>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 15,
                }}
              >
                +91-98244 97153
              </p>
              <h4 className="site-text-primary">Email address</h4>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 16,
                }}
              >
                info@cometgroup.co.in
              </p>

              <h4 className="site-text-primary">Address info</h4>
              <p
                style={{
                  fontWeight: "400",
                  whiteSpace: 28,
                  fontSize: 15,
                }}
              >
                FF-01 Block C-2, Nirman Complex, R C Technical Road, Ghatlodia,
                Ahmedabad{" "}
              </p>
            </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="section-full m-t30">
        <div className="container  py-5">
          <div className="section-head">
            <h2 className="ms-5">Contact Form </h2>
            <div className="mt-separator-outer m-b30 ms-5">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          <div className="section-content m-b50">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="sx-shipment-wrap">
                  <div className="mt-box m-b30">
                    <div className="row-container">
                      <h2 className="display-block fs-4  m-t25 m-b50">
                        Please contact us for a free quotation and tell us more
                        about your project
                      </h2>
                    </div>
                    <p
                      className="m-b25"
                      style={{
                        fontWeight: "400",
                        whiteSpace: 28,
                        fontSize: 17,
                      }}
                    >
                      Thank you for your interest in requesting a work estimate,
                      please fill out the form and we will get back to you
                      shortly.
                    </p>
                    <p
                      className=""
                      style={{
                        fontWeight: "500",
                        whiteSpace: 28,
                        fontSize: 18,
                      }}
                    >
                      <i className="fa fa-phone site-text-primary fs-3" />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; +91-98240 40260{" "}
                    </p>
                    <p
                      className="list-check-circle primary m-t50"
                      style={{
                        fontWeight: "400",
                        whiteSpace: 28,
                        fontSize: 18,
                      }}
                    >
                      Tell us a bit about your project and we will match you
                      with the perfect local contractor for FREE.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 p-a30 bg-gray radius-10 cons-contact-form-wrap">
                <form className="" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="companyName"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Company Name"
                          value={formData.companyName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="dealingWith"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Dealing With"
                          value={formData.dealingWith}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          required
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          name="contactNo"
                          type="text"
                          className="form-control"
                          required
                          placeholder="Contact No"
                          value={formData.contactNo}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={5}
                          className="form-control"
                          required
                          placeholder="Message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        name="Resat"
                        type="reset"
                        value="Reset"
                        className="site-button m-r10"
                      >
                        Reset
                      </button>
                      <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="site-button-secondry"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full m-t10">
        <div className="container">
          <div className="row gt-1 text-center">
            <div className="col-1 ms-5 "></div>
            <div className="col-lg-3 col-md-3 m-b50 bg-gray mt-product bdr-1 bdr-solid bdr-gray-light">
              <div className="mt-icon p-a60 text-center m-b10 ">
                <div className="icon-md m-b15">
                  <i className="iconmoon-smartphone-1 fs-4 site-text-primary" />
                </div>
                <h5
                  className="mt-tilte m-b10"
                  style={{
                    fontWeight: "460",
                    fontSize: 25,
                  }}
                >
                  <div className="icon-content" />
                  <h4 className="site-text-primary">Phone number</h4>
                </h5>
                <p
                  className="fs-6 justify-content-center mt-5"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  <p>+91-98240 40260</p>
                  <p>+91-98244 97153</p>
                </p>
                <span className="icon-arrow fs-6 site-text-primary"></span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 m-b50 bg-gray mt-product bdr-1 bdr-solid bdr-gray-light">
              <div className="mt-icon p-a60 text-center m-b10 ">
                <div className="icon-md m-b15">
                  <i className="iconmoon-email fs-4 site-text-primary" />
                </div>
                <h5
                  className="mt-tilte m-b20"
                  style={{
                    fontWeight: "460",
                    fontSize: 25,
                  }}
                >
                  <div className="icon-content" />
                  <h4 className="site-text-primary">Email address</h4>
                </h5>
                <p
                  className="fs-6 justify-content-between mt-5"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {" "}
                  <p>
                    <u>info@cometgroup.co.in</u>
                  </p>
                </p>{" "}
                <span className="icon-arrow fs-6 site-text-primary"></span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 m-b50 bg-gray mt-product bdr-1 bdr-solid bdr-gray-light">
              <div className="mt-icon p-a60 text-center m-b10 ">
                <div className="icon-md m-b15">
                  <i className="iconmoon-travel fs-4 site-text-primary" />
                </div>
                <h5
                  className="mt-tilte m-b20"
                  style={{
                    fontWeight: "460",
                    fontSize: 25,
                  }}
                >
                  <div className="icon-content" />
                  <h4 className="site-text-primary ">Address info</h4>
                </h5>
                <p
                  className="fs-6 mt-4 mt-5 text-start"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  FF-01 Block C-2, Nirman Complex, R C Technical Road,
                  Ghatlodia, Ahmedabad{" "}
                </p>{" "}
                <span className="icon-arrow fs-6 site-text-primary"></span>
              </div>
            </div>

            <div className="ms-2 "></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContactUsPage;
