import { NavLink } from "react-router-dom";
import { publicUrlFor, route } from "../../../../globals/constants";

function SectionBanner(props) {
  const { data } = props;
  console.log("banner", data);
  console.log("bannerd", data.background);
  return (
    <>
      <div
        className="mt-bnr-inr overlay-wraper"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "media/bgimg/" + data.background
          )})`,
        }}
      >
        <div className="bg-block opacity-01" />
        <div className="container">
          <div className="mt-bnr-inr-entry text-center">
            <div className="m-b50">
              <h1 className="text-info" style={{
                  fontWeight: "500",
                  fontSize:38
                }}>{data.title}</h1>
              {/* Breadcrumb */}
              {/* <div className="mt-separator-outer m-b30">
                <div className="mt-separator site-bg-success" />
              </div> */}
            </div>
            <ul className="mt-breadcrumb breadcrumb-style-1">
              <li>
                <NavLink to={route.home.HOME1} className="fs-5">Home</NavLink>
              </li>
              <li className="fs-5 text-info">{data.crumb}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default SectionBanner;
