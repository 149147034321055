import { route, publicUrlFor } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";

function SectionFooter1(props) {
  return (
    <>
      <footer className={"site-footer " + props.theme}>
        {/* Footer Top Part */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* About */}
              {/* <div className="col-lg-1 col-md-12"></div> */}
              {/* About */}
              <div className="col-lg-2 col-md-12 ms-2">
                <div className=" widget_about">
                  <h3 className="widget-title">About Company</h3>
                  <div className="logo-footer clearfix">
                    {/* <NavLink to={route.home.HOME1}><FastImage src="images/footer-logo.png" width={171} height={49} alt="" /></NavLink> */}
                    <NavLink to={route.home.HOME1}>
                      <FastImage
                        src="media/logos/CometGroup_logo.jpg"
                        width={95}
                        height={30}
                        alt=""
                      />
                    </NavLink>
                    <p className="m-t15" style={{ fontSize: 15 }}>
                      Leading the way in innovative “Water & Wastewater”
                      treatment solutions for a cleaner future.
                    </p>
                    <ul className="list-unstyled">
                      <li>
                        <i className="fa fa-location-arrow site-text-primary fs-5" />{" "}
                        <b className="p-lr5 text-uppercase">Location :- </b>
                        <p className="" style={{ fontSize: 15 }}>
                          FF-01 Block C-2, Nirman Complex, R C
                          Technical Road, Ghatlodia, Ahmedabad
                        </p>
                      </li>
                    </ul>{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 ms-5 col-md-12">
                <div className="widget_services m-b20">
                  <h3 className="widget-title">Products</h3>
                  <ul className=" nav navbar-nav text-uppercase">
                    {/* <li>
                      <NavLink to={route.pages.HOME1}>Home</NavLink>
                    </li> */}
                    <li>
                      <NavLink to={route.pages.product.BoilerWaterChemicals}>
                        Boiler Water Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.CoolingWaterChemicals}>
                        Cooling Water Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.SideTreatments}>
                        Fire Side Treatments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.RoAntiScalantKits}>
                        RO Anti-Scalant & De-Scalant
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.TestKits}>
                        Test Kits
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <ul>
                        {" "}
                        <NavLink to={route.pages.product.DescalingChemicals}>
                          Descaling Chemicals
                        </NavLink>
                      </ul>
                    </li>
                    <li>
                      <NavLink to={route.pages.product.ChillingWaterChemicals}>
                        Chilling Water Chemicals
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 ms-5 col-md-12 ">
                <div className="widget_services m-b20">
                  <h3 className="widget-title">Useful links</h3>
                  <ul className="text-uppercase list-unstyled">
                    {/* <li>
                      <NavLink to={route.pages.HOME1}>Home</NavLink>
                    </li> */}
                    <li>
                      <NavLink to={route.pages.ABOUT}>About Us</NavLink>
                    </li>
                    <li>
                      <NavLink to={route.services.SERVICES}>Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.knowledge.KNOWLEDGE}>
                        Knowledge
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.chemical.CHEMICALS}>
                        Chemicals
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={route.pages.SUPPORT}>Support</NavLink>
                    </li>
                    {/* <li>
                      <NavLink to={route.pages.sitemap.SITEMAP}>
                        Site Map
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink to={route.pages.CONTACTUS}>Contact Us</NavLink>
                    </li>
                  </ul>
                </div>
                {/* <div className="col-lg-1 col-md-12"></div> */}
              </div>
              <div className="col-lg-3 ms-5 col-md-12">
                <h3 className="widget-title">Contact Us</h3>
                <ul className="list-unstyled ">
                  {/* <li>
                      <NavLink to={route.pages.HOME1}>Home</NavLink>
                    </li> */}
                  <li>
                    {" "}
                    <p className="fs-6">
                      <i className="fa fa-phone site-text-primary fs-4" />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; +91-98240 40260{" "}
                    </p>
                  </li>
                  <hr />
                  <li>
                    {" "}
                    <p className="fs-6 ">
                      <i className="fa fa-phone site-text-primary fs-4" />{" "}
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; +91- 98244 97153{" "}
                    </p>
                    {/* (987)
                      654-3210{" "} */}
                  </li>
                  <hr />
                  {/* <li>
                    {" "}
                    <p className="fs-6">
                      <i className="fa fa-phone site-text-primary fs-4" />
                      &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; +91-98244 97153
                    </p>
                  </li>
                  <hr /> */}
                  <li>
                    <p className="fs-6">
                      <i className="fa fa-envelope site-text-primary fs-4" />{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      <u> info@cometgroup.co.in</u>
                    </p>
                  </li>
                </ul>
                {/* <div className="col-lg-1 col-md-12"></div> */}
              </div>
              {/* Resent Post */}
              {/* <div className="col-lg-3 col-md-6">
                <div className="widget recent-posts-entry-date">
                  <h4 className="widget-title">Resent Post</h4>
                  <div className="widget-post-bx">
                    <div className="widget-post clearfix">
                      <div className="mt-post-media text-white  overflow-hide">
                        <FastImage
                          src="images/blog/recent-blog/pic1.jpg"
                          alt=""
                        />
                      </div>
                      <div className="mt-post-info">
                        <div className="mt-post-header">
                          <div className="post-title">
                            <NavLink to={route.blog.POST_SINGLE}>
                              A partnership based trust.
                            </NavLink>
                          </div>
                        </div>
                        <div className="mt-post-meta">
                          <ul>
                            <li className="post-author">
                              <i className="fa fa-user site-text-primary" />
                              By Admin
                            </li>
                            <li className="post-comment">
                              <i className="fa fa-comments site-text-primary" />{" "}
                              28
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="widget-post clearfix">
                      <div className="mt-post-media  text-white  overflow-hide">
                        <FastImage
                          src="images/blog/recent-blog/pic2.jpg"
                          alt=""
                        />
                      </div>
                      <div className="mt-post-info">
                        <div className="mt-post-header">
                          <div className="post-title">
                            <NavLink to={route.blog.POST_SINGLE}>
                              For the men in charge life.{" "}
                            </NavLink>
                          </div>
                        </div>
                        <div className="mt-post-meta">
                          <ul>
                            <li className="post-author">
                              <i className="fa fa-user site-text-primary" />
                              By Admin
                            </li>
                            <li className="post-comment">
                              <i className="fa fa-comments site-text-primary" />{" "}
                              29
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="widget-post clearfix">
                      <div className="mt-post-media  text-white  overflow-hide">
                        <FastImage
                          src="images/blog/recent-blog/pic3.jpg"
                          alt=""
                        />
                      </div>
                      <div className="mt-post-info">
                        <div className="mt-post-header">
                          <div className="post-title">
                            <NavLink to={route.blog.POST_SINGLE}>
                              Fueling your logistic chain.
                            </NavLink>
                          </div>
                        </div>
                        <div className="mt-post-meta">
                          <ul>
                            <li className="post-author">
                              <i className="fa fa-user site-text-primary" />
                              By Admin
                            </li>
                            <li className="post-comment">
                              <i className="fa fa-comments site-text-primary" />{" "}
                              30
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Instagram Feed */}
              {/* <div className="col-lg-3 col-md-6">
                <div className="widget widget_newsletter f-news-letter">
                  <h4 className="widget-title">Newsletter</h4>
                  <div className="newsletter-bx m-b30">
                    <form role="search" method="post">
                      <div className="input-group">
                        <input
                          name="news-letter"
                          className="form-control"
                          placeholder="ENTER YOUR EMAIL"
                          type="text"
                        />
                        <span className="input-group-btn">
                          <button
                            type="submit"
                            className="site-button btn font-weight-600"
                          >
                            <i className="fa fa-paper-plane" />
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="widget widget_gallery mfp-gallery clearfix">
                  <h4 className="widget-title">Instagram Feed</h4>
                  <ul className="clearfix">
                    <li>
                      <div className="mt-post-thum  overflow-hide">
                        <a
                          href={publicUrlFor("images/gallery/pic1.jpg")}
                          className="mfp-link"
                        >
                          <FastImage
                            src="images/gallery/thumb/pic1.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="mt-post-thum  overflow-hide">
                        <a
                          href={publicUrlFor("images/gallery/pic2.jpg")}
                          className="mfp-link"
                        >
                          <FastImage
                            src="images/gallery/thumb/pic2.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="mt-post-thum  overflow-hide">
                        <a
                          href={publicUrlFor("images/gallery/pic3.jpg")}
                          className="mfp-link"
                        >
                          <FastImage
                            src="images/gallery/thumb/pic3.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="mt-post-thum  overflow-hide">
                        <a
                          href={publicUrlFor("images/gallery/pic4.jpg")}
                          className="mfp-link"
                        >
                          <FastImage
                            src="images/gallery/thumb/pic4.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="mt-post-thum  overflow-hide">
                        <a
                          href={publicUrlFor("images/gallery/pic5.jpg")}
                          className="mfp-link"
                        >
                          <FastImage
                            src="images/gallery/thumb/pic5.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="mt-post-thum  overflow-hide">
                        <a
                          href={publicUrlFor("images/gallery/pic6.jpg")}
                          className="mfp-link"
                        >
                          <FastImage
                            src="images/gallery/thumb/pic6.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="text-center"> */}
        {/* <div className="footer-bottom bg-light">
          <div className="overlay-main" />
          <div className="container m-t25">
            <div className="mt-footer-bot-center">
              <span className="copyrights-text p-t15 text-uppercase">
                <NavLink to={route.home.HOME1}>Home</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <NavLink to={route.pages.ABOUT}>About Us</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <NavLink to={route.services.SERVICES}>Services</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <NavLink to={route.pages.knowledge.KNOWLEDGE}>
                  Knowledge
                </NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <NavLink to={route.pages.chemical.CHEMICALS}>Chemicals</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <NavLink to={route.pages.CONTACTUS}>Contact Us</NavLink>
                &nbsp;&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <NavLink to={route.pages.sitemap.SITEMAP}>Site Map</NavLink>
              </span>
            </div>
          </div>
        </div> */}
        {/* Footer Copyright Part */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container p-t30">
            <div className="mt-footer-bot-center">
              <span className="copyrights-text fs-6">
                2024 © Copyright CometGroup. All rights reserved. Read Legal
                policy and Privacy policy.
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default SectionFooter1;
