import React from "react";
import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
function CometProducts() {
  return (
    <div
      className="section-full p-b80 bg-center bg-full-height bg-no-repeat"
      // style={{
      //   backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
      // }}
    >
      <div className="container p-t80">
        {/* <div className="section-content"> */}
        {/* TITLE START */}
        <div className="section-head text-center">
          <h2 data-title="Products" className="text-secondary text-uppercase">
            Our Products
          </h2>
          <div className="mt-separator-outer m-b30">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END */}
        <div className="row m-t20 ">
          {/* Block 1 */}
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                {/* <div className="col-lg-4 col-md-6 col-xs-100pc m-b30">
              <div className="mt-box mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom"></div> */}
                <FastImage
                  src="media/bgimg/BoilerWater.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Boiler Water Chemicals</h4>
                  <div className="p-tb10">
                    {" "}
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.BoilerWaterChemicals}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Block 2 */}
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/CoolingWaterTreatment.jpg"
                  width={130}
                  height={5}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Cooling Water Chemicals</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.CoolingWaterChemicals}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-t20 ">
          {/* Block 3 */}
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/FireSideTreatment.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Fire Side Treatment</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.SideTreatments}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Block 4 */}
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/ROAntiScalant.jpg"
                  alt=""
                  width={30}
                  height={15}
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Ro Anti Scalant Kits</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.RoAntiScalantKits}
                      // title="wish list"
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-t20">
          {/* Block 5 */}
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b22 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/TestingKit.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-2">
                  <h4 className="mt-title">Test Kits</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.TestKits}
                      // title="wish list"
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Block 6 */}
         <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/DisclaingProcess.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-4">
                <h4 className="mt-title">Descaling Chemicals</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.DescalingChemicals}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          {/* <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/parer-slim.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Parer Slimicides</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.ParerSlimicides}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row m-t20 ">
          {/* Block 7 */}
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/ChillerPlant.jpg"
                  alt=""
                  width={30}
                  height={15}
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Chilling Water Chemicals</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.ChillingWaterChemicals}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/sugar-processing.jpg"
                  alt=""
                  width={30}
                  height={15}
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Sugar Processing Chemicals</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.SugarProcessing}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Block 8
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/preteatment-biotechnology.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Pretreatment Chemicals</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.Pretreatment}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="row m-t20 ">
          Block 9
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage src="media/bgimg/heattreatmentsault.jpg" alt="" />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Heat Treatment Salts</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.HeatTreatmentSalts}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          Block 10
          <div className="col-lg-6 col-md-6">
            <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
              <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                <FastImage
                  src="media/bgimg/Biotechnology.jpg"
                  width={30}
                  height={15}
                  alt=""
                />
              </div>
              <div className="mt-info  text-center">
                <div className="p-a10 mt-3">
                  <h4 className="mt-title">Biotechnology For ETP</h4>
                  <div className="p-tb10">
                    <NavLink
                      className="mfp-link"
                      to={route.pages.product.Biotechnology}
                    >
                      <button className="site-button" type="button">
                        Read More
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default CometProducts;
