import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";

function SectionHomeWeDo() {
  return (
    <>
      {/* <div className='card mb-xl-10'>
      <div id='kt_account_profile_details' className='collapse show'> */}
      <div
        className="section-fullscreen w-100 p-b80 bg-center bg-cover bg-full-height bg-full-height bg-no-repeat bg-gray"
        // style={{
        //   backgroundImage: `url(${publicUrlFor(
        //     "images/background/bg-map.png"
        //   )})`,
        // }}
      >
        {" "}
        <div className="row m-b10 gt-1">
          {/* Spacer column */}
          <div className="col-lg-1 ms-5"></div>

          {/* Product Section */}
          <div className="col-lg-3 col-md-6 m-b50 bg-white hover-shadow-secondary mt-product bdr-1 bdr-solid bdr-gray-light">
            <div className="mt-icon p-a60 align-center m-b10">
              <h5
                className="mt-title m-b20"
                style={{ fontWeight: 460, fontSize: 25 }}
              >
                Our Products
              </h5>
              <p className="fs-6" style={{ fontWeight: 400 }}>
                Comet Chemicals provides top-quality water treatment solutions
                including boiler feed chemicals, cooling water treatments,
                Biocide, RO Treatment Chemicals, Effluent Treatment Chemicals,
                and Test Kits.
              </p>
              <span className="icon-arrow fs-6 site-text-primary">
                <NavLink to={route.pages.product.PRODUCTS}>
                  Learn More &nbsp; <b className="fa fa-arrow-right"></b>
                </NavLink>
              </span>
            </div>
          </div>

          {/* Service Section */}
          <div className="col-lg-3 col-md-6 m-b50 bg-white mt-product bdr-1 bdr-solid bdr-gray-light">
            <div className="mt-icon p-a60 align-center m-b25">
              <h5
                className="mt-title m-b25"
                style={{ fontWeight: 460, fontSize: 25 }}
              >
                Our Services
              </h5>
              <p className="fs-6 align-center" style={{ fontWeight: 400 }}>
                Comet Chemicals offers on-site water analysis and tailor-made
                treatment programs for efficient water management solutions.
              </p>
              <span className="icon-arrow fs-6 site-text-primary">
                <NavLink to={route.services.SERVICES}>
                  Learn More &nbsp; <b className="fa fa-arrow-right"></b>
                </NavLink>
              </span>
            </div>
          </div>

          {/* Knowledge Section */}
          <div className="col-lg-3 col-md-6 m-b50 bg-white mt-product bdr-1 bdr-solid bdr-gray-light">
            <div className="mt-icon p-a60 justify-center">
              <h5
                className="mt-title m-b25"
                style={{ fontWeight: 460, fontSize: 25 }}
              >
                Knowledge
              </h5>
              <p className="fs-6 " style={{ fontWeight: 400 }}>
                Discover essential insights on water composition, impurities,
                treatment methods, and effective water management strategies.
              </p>
              <span className="fs-6 site-text-primary">
                <NavLink to={route.pages.knowledge.KNOWLEDGE}>
                  Learn More &nbsp; <b className="fa fa-arrow-right"></b>
                </NavLink>
              </span>
            </div>
          </div>

          {/* Spacer column */}
          <div className="ms-2"></div>
        </div>
        <div className="container">
          {" "}
          {/* <div className="section-content"> */}
          {/* </div> */}
          {/* <div className="card my-3 bdr-1">
            <div className="card-body"> */}
          <div className="section-head m-t0 display-block text-center">
            <h3 data-title="" className="text-info fs-2">
              A FEW WORDS ABOUT OUR COMPANY ...
            </h3>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          <div className="row  justify-content-between  testimonial-2">
            {/* <div className="col-lg-3 col-md-12 ms-5">
              <div className="mt-box m-b10">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/images/image_symbol.jpg"
                    alt=""
                    width={43}
                    height={15}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-1 col-md-12 ms-5"></div>
            <div className="col-lg-6 col-md-12"> */}
            {/* <div className="mt-box sx-what-wedo m-b25 ps-5 testimonial-2  clearfix bg-white"> */}
            <p
              className="ms-6 d-block justify-between-center"
              style={{
                fontWeight: "400",
                whiteSpace: 28,
                fontSize: 18,
              }}
            >
              <b>Comet Chemicals</b> is an industry leading manufacturer &
              supplier of high-quality water and wastewater treatment chemicals,
              speciality chemicals, water test kits, reagents, chemical dosing
              and control equipment. Through our commitment to cutting-edge
              technology, innovation, quality products and service excellence we
              deliver significant advantage to our valued customers and
              partners.
              <br />
              <br />
              Your Water Treatment Chemical manufacturing industry's global ally
              for sustainable chemical solutions.
              {/* </b> */}
              <br />
              <br />
              {/* <b className="ms-6 d-block justify-between-center"> */}
              Empowering industries for a sustainable future.
              {/* </b>{" "} */}
            </p>{" "}
            {/* </div>
            </div> */}
          </div>
          {/* <div className="row">
            {" "}
            <div className="col-lg-4 col-md-6 ms-5 mx-1">
              <div className="mt-box m-b10">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/images/image_water_drop.jpg"
                    alt=""
                    width={30}
                    height={15}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 mt-5 ms-2">
              <div className="mt-box sx-what-wedo">
                <span className="text-info display-block title-second text-uppercase font-20 font-weight-600 display-block m-b15">
                  Contacts
                </span>
                <p>A 1/21, Nirman Complex,</p>
                <p>
                  {" "}
                  R.C Technical Road
                  <br />
                  Ghatlodia,Ahmedabad
                  <br />
                  Tel. : +91-079-27449045
                  <br />
                  Fax : +91-079-27437067
                  <br /> Call : +91-98240 40260, <br /> &nbsp; &nbsp;&nbsp;
                  &nbsp;&nbsp; &nbsp; +91-98244 97153 <br />
                  <a href="#">
                    <u>Email: info@cometgroup.co.in </u>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-12 mt-5">
              <div className="mt-box m-b2 img-overlay img-effect-zoom">
                <FastImage
                  src="media/images/image_homecontactus.jpg"
                  alt=""
                  width={85}
                  height={15}
                />
              </div>
            </div>
          </div> */}
        </div>
        {/* Products */}
        {/* <div className="section-full p-t50"> */}
        <div className="container p-4">
          {/* <div className="section-content"> */}
          {/* TITLE START */}
          <div className="section-head">
            {/* <p className="text-warning text-uppercase text-center fs-6">
              Your Water Treatment Chemical manufacturing industry's global ally
              for sustainable chemical solutions.
            </p>
            <p data-title="Products" className="text-info fs-1 text-center">
              Empowering industries for a sustainable future.
            </p>{" "} */}
            <p
              className="ms-6 d-block text-center"
              style={{
                fontWeight: "400",
                whiteSpace: 28,
                fontSize: 18,
              }}
            >
              Comet Chemicals supplies high-quality water and wastewater
              treatment chemicals, specialty chemicals, test kits, reagents,
              analysis, dosing, and control equipment globally. Our commitment
              ensures customer advantage.
            </p>
            {/* <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div> */}
          </div>
          {/* TITLE END */}
          <div className="row m-t20 ">
            {/* Block 1 */}
            <div className="col-lg-4 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  {/* <div className="col-lg-4 col-md-6 col-xs-100pc m-b30">
              <div className="mt-box mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img-overlay4 mt-img-effect zoom"></div> */}
                  <FastImage
                    src="media/bgimg/BoilerWater.jpg"
                    width={30}
                    height={15}
                    alt=""
                  />
                </div>
                <div className="mt-info align-center">
                  <div className="p-a20 mt-3">
                    <h5
                      className="mt-title"
                      style={{ foontWeight: 500, fontSize: 22 }}
                    >
                      Boiler Water Treatment Chemicals
                    </h5>
                    <hr />
                    <div className="p-tb20">
                      {" "}
                      <p
                        className="fs-6 justify-content-between text-align-center"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Boiler Water Treatment Chemicals to improve efficiency
                        of boiler and keep Boiler free of scale & corrosion.
                      </p>{" "}
                      <a className="mfp-link" href={route.pages.ABOUT}>
                        <span className="icon-arrow fs-6 site-text-primary">
                          Read More &nbsp;&nbsp;&nbsp;
                          <b className="fa fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Block 2 */}
            <div className="col-lg-4 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/CoolingWaterTreatment.jpg"
                    width={30}
                    height={15}
                    alt=""
                  />
                </div>
                <div className="mt-info align-center">
                  <div className="p-a20 mt-3">
                    <h5
                      className="mt-title"
                      style={{ foontWeight: 500, fontSize: 22 }}
                    >
                      Cooling Water Treatment Chemicals
                    </h5>
                    <hr />
                    <div className="p-tb10">
                      <p
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Comet Chemicals can help you design a program that will
                        meet all your cooling water system needs free from
                        Scale, Corrosion & Biocide contamination.{" "}
                      </p>{" "}
                      <a className="mfp-link" href={route.pages.ABOUT}>
                        <span className="icon-arrow fs-6 site-text-primary">
                          Read More &nbsp;&nbsp;&nbsp;
                          <b className="fa fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div>
          <div className="row m-t20 "> */}
            {/* Block 3 */}
            <div className="col-lg-4 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mb-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/EffluentTreatmentChemicals.jpg"
                    // width={30}
                    // height={5}
                    alt=""
                  />
                </div>
                <div className="mt-info  align-center">
                  <div className="p-a20 mt-3">
                    <h5
                      className="mt-title"
                      style={{ foontWeight: 500, fontSize: 22 }}
                    >
                      Effluent Treatment Chemicals
                    </h5>
                    <hr />
                    <div className="p-tb10">
                      <p
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        Comet Chemicals offers efficient solutions for degrading
                        organic/inorganic compounds in wastewater, enhancing
                        overall water quality.{" "}
                      </p>{" "}
                      <a className="mfp-link" href={route.pages.ABOUT}>
                        <span className="icon-arrow fs-6 site-text-primary">
                          Read More &nbsp;&nbsp;&nbsp;
                          <b className="fa fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Block 4 */}
            {/* <div className="col-lg-12 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/ro-scalant.jpg"
                    alt=""
                    width={30}
                    height={15}
                  />
                </div>
                <div className="mt-info  text-center">
                  <div className="p-a10 mt-3">
                    <h4 className="mt-title">Ro Anti Scalant Kits</h4>
                    <div className="p-tb10">
                      <a
                        className="mfp-link"
                        href={route.pages.product.RoAntiScalantKits}
                      >
                        <button className="site-button" type="button">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div className="row m-t20 ">
            <div className="col-lg-6 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/testkit.jpg"
                    width={30}
                    height={15}
                    alt=""
                  />
                </div>
                <div className="mt-info  text-center">
                  <div className="p-a10 mt-3">
                    <h4 className="mt-title">Test Kits</h4>
                    <div className="p-tb10">
                      <a
                        className="mfp-link"
                        href={route.pages.product.TestKits}
                      >
                        <button className="site-button" type="button">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/parer-slim.jpg"
                    width={30}
                    height={15}
                    alt=""
                  />
                </div>
                <div className="mt-info  text-center">
                  <div className="p-a10 mt-3">
                    <h4 className="mt-title">Parer Slimicides</h4>
                    <div className="p-tb10">
                      <a
                        className="mfp-link"
                        href={route.pages.product.TestKits}
                      >
                        <button className="site-button" type="button">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-t20 ">
            <div className="col-lg-6 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/sugar-processing.jpg"
                    alt=""
                    width={30}
                    height={15}
                  />
                </div>
                <div className="mt-info  text-center">
                  <div className="p-a10 mt-3">
                    <h4 className="mt-title">Sugar Processing Chemicals</h4>
                    <div className="p-tb10">
                      <a
                        className="mfp-link"
                        href={route.pages.product.SugarProcessing}
                      >
                        <button className="site-button" type="button">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/preteatment-biotechnology.jpg"
                    width={30}
                    height={15}
                    alt=""
                  />
                </div>
                <div className="mt-info  text-center">
                  <div className="p-a10 mt-3">
                    <h4 className="mt-title">Pretreatment Chemicals</h4>
                    <div className="p-tb10">
                      <a
                        className="mfp-link"
                        href={route.pages.product.Pretreatment}
                      >
                        <button className="site-button" type="button">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-t20 ">
            <div className="col-lg-6 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/heattreatmentsault.jpg"
                    alt=""
                  />
                </div>
               
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="mt-box m-b25 mt-product-box bdr-1 bdr-solid bdr-gray-light testimonial-2  clearfix bg-white">
                <div className="mt-thum-bx mt-img mt-img-overlay4 mt-img-effect zoom">
                  <FastImage
                    src="media/bgimg/Biotechnology.jpg"
                    width={30}
                    height={15}
                    alt=""
                  />
                </div>
                <div className="mt-info  text-center">
                  <div className="p-a10 mt-3">
                    <h4 className="mt-title">Biotechnology For ETP</h4>
                    <div className="p-tb10">
                      <a
                        className="mfp-link"
                        href={route.pages.product.Biotechnology}
                      >
                        <button className="site-button" type="button">
                          Read More
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>{" "}
        {/* <div className="section-container">
          <div className="">
            <FastImage
              src="media/images/download.jpg"
              alt=""
              width={1500}
              height={100}
            />
          </div>
        </div> */}
        <div
          className="section-full p-b40 bg-center bg-full-height bg-no-repeat bg-gray"
          // style={{
          //   backgroundImage: `url(${publicUrlFor(
          //     "images/background/bg-map.png"
          //   )})`,
          // }}
        >
          <p className="text-warning text-uppercase text-center m-t40 m-b30 fs-5">
            OUR VISION FOR GROWTH IS DRIVEN BY SUSTAINABLE CHEMISTRY INNOVATION
          </p>
          <p className="text-center " style={{ fontSize: 32, fontWeight: 500 }}>
            Since 2001, Comet Chemicals leads with tailer-made solutions,{" "}
          </p>
          <p
            className="text-center m-b20 "
            style={{ fontSize: 32, fontWeight: 500 }}
          >
            boasting more than 20 years of industrial excellence.
          </p>{" "}
          <div className="row m-t80 ">
            <div className="col-lg-6 ms-2 col-md-12">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b30">
                  <h2 className="display-block text-start fs-3 m-t25 m-b40">
                    We've provided water treatment chemical solutions in all
                    type of industries for more than two decades
                  </h2>
                  <p
                    className="m-b15"
                    style={{
                      fontWeight: "400",
                      whiteSpace: 25,
                      fontSize: 18,
                    }}
                  >
                    Comet Chemicals has been providing reliable, high quality,
                    cost-effective water treatment chemicals to the industry for
                    over two decades. We offer a wide range of water treatment
                    chemicals and solutions that focus on rectifying problems of
                    water and maximizing the performance of water treatment
                    chemicals. Comet Chemicals formulates & manufactures water
                    treatment chemicals through state of art manufacturing
                    facility.
                  </p>
                  <ul
                    className="list-check-circle primary m-t50"
                    style={{
                      fontWeight: "500",
                      whiteSpace: 28,
                      fontSize: 17,
                    }}
                  >
                    <li className="">
                   Pioneer in Sustainable Manufacturing Techniques{" "}
                    </li>
                    <li>Customized solutions for better water treatment </li>
                    <li>
                    Industry Recognition for Outstanding Contributions to Green Technology
                    </li>
                    {/* <br />
                    <li>
                      Industry Recognition for Outstanding Contributions to
                      Green Technology
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-6"></div>
            <div className="col-lg-4 m-t20 col-md-6">
              <div className="mt-box mt-product-box bdr-1 bdr-solid bdr-gray-light">
                <div className="mt-thum-box mt-img mt-img-effect">
                  <FastImage
                    src="media/images/checmical-lab.png"
                    width={200}
                    height={10}
                    alt=""
                  />
                </div>
              </div>{" "}
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
}

export default SectionHomeWeDo;
