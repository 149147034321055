import { useEffect, useState } from "react";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { Link, NavLink } from "react-router-dom";

function NewSectionHeader() {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    loadScript("js/sticky-header.js");
  });

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <header
        className={
          "site-header header-style-1 mobile-sider-drawer-menu " +
          (isActive ? "active" : "")
        }
        style={{ backgroundColor: "#D3E3F3" }}
      >
        <div className="top-bar top-bar-liner bg-secondry">
          <div className="container">
            <div className="row">
              <div className="mt-topbar-right">
                {/* <ul className="list-unstyled  tb-info-liner">
                <li>
                  <i className="site-text-primary" />
                  Your Trusted 24 Hours Service Provider!
                </li>
              </ul> */}
                <ul className="list-inline tb-social-liner">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="fa fa-facebook"
                    />
                  </li>
                  <li>
                    <a href="https://twitter.com/" className="fa fa-twitter" />
                  </li>
                  <li>
                    <a
                      href="https://in.linkedin.com/"
                      className="fa fa-linkedin"
                    />
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/"
                      className="fa fa-instagram"
                    />
                  </li>
                </ul>{" "}
                <ul className="list-unstyled  tb-info-liner">
                  <li>
                    {" "}
                    &nbsp;&nbsp;
                    <i className="fa fa-phone site-text-primary" />
                    +91-98240 40260 &nbsp;&nbsp;
                  </li>
                  <li>
                    {" "}
                    &nbsp;&nbsp;
                    <i className="fa fa-envelope site-text-primary" />{" "}
                    info@cometgroup.co.in &nbsp;&nbsp;
                  </li>
                  <li>
                    {" "}
                    &nbsp;&nbsp;{" "}
                    <i className="fa fa-location-arrow site-text-primary" />{" "}
                    Ahmedabad &nbsp;&nbsp;
                  </li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>
        {/* Search Link */}
        <div className="header-middle">
          <div className="container">
            <div className="logo-header">
              <NavLink to={route.home.HOME1}>
                <FastImage
                  src="media/logos/CometGroup_logo.jpg"
                  width={216}
                  height={37}
                  alt=""
                />
              </NavLink>
              {/* </div> */}
            </div>
            <div className="header-info me-2">
              <ul>
                <li>
                  <div className="p-tb20">
                    <a
                      className="mfp-link"
                      href={route.pages.CONTACTUS}
                      // title="wish list"
                    >
                      <button className="site-button bdr-primary" type="button">
                        Contact Us
                      </button>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="header-info">
            <ul>
              <li>
                <div>
                  <div className="icon-sm">
                    <span className="icon-cell  site-text-primary">
                      <i className="iconmoon-smartphone" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <strong>Phone Number</strong>
                    <span>+91-98240 40260</span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="icon-sm">
                    <span className="icon-cell  site-text-primary">
                      <i className="iconmoon-envelope" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <strong>Email Address</strong>
                    <span>info@cometgroup.co.in</span>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
          </div>
        </div>
        <div className="container full-w-screen-2xl container md:px-20 px-4 mx-auto">
        
        <div className="row col-lg-11 col-md-12 ms-5">
          {/* <div className=""> */}{" "}
          <div className="ms-6"></div> 
          <div className="ms-6"></div> 
          {/* <div className="col-lg-1"></div> */}
          <div className="sticky-header main-bar-wraper fixed top-0 left-0 right-0 z-50 text-end">
            <div
              className="main-bar header-botton"
              style={{ backgroundColor: "#D3E3F3" }}
            >
              {/* <div className="container"> */}
              {/* NAV Toggle Button */}
              <button
                // id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
                onClick={handleToggleMenu}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>

              <div className="header-nav wadge navbar-collapse  hidden lg:flex justify-content-around bg-white p-2">
              {/* <div className="header-nav wadge navbar-collapse justify-content-around bg-white p-2"> */}
                <ul className="nav navbar-nav">
                  <li className="active">
                    <NavLink
                      to={route.home.HOME1}
                      style={{
                        fontWeight: "400",
                      }}
                      onClick={handleToggleMenu}
                    >
                      Home
                    </NavLink>
                    {/* <ul className="sub-menu"></ul> */}
                  </li>{" "}
                  <li>
                    <NavLink
                      to={route.pages.product.PRODUCTS}
                      style={{
                        fontWeight: "400",
                      }}
                      onClick={handleToggleMenu}
                    >
                      Our Products
                      <i className="fa fa-chevron-down" />
                    </NavLink>
                    <ul className="sub-menu text-start">
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.BoilerWaterChemicals}>
                          BOILER WATER CHEMICALS
                        </NavLink>
                      </li>
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.CoolingWaterChemicals}>
                          COOLING WATER CHEMICALS
                        </NavLink>
                      </li>
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.SideTreatments}>
                          FIRE SIDE TREATMENTS
                        </NavLink>
                      </li>
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.RoAntiScalantKits}>
                          RO ANTI-SCALANT & DE-SCALANT
                        </NavLink>
                      </li>
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.TestKits}>
                          TEST KITS
                        </NavLink>
                      </li>
                      <li
                        className="fs-6 text-uppercase"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.DescalingChemicals}>
                        Descaling Chemicals
                        </NavLink>
                      </li>
                      <li
                        className="fs-6 text-uppercase"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.ChillingWaterChemicals}>
                        Chilling Water Chemicals
                        </NavLink>
                      </li>
                      {/* <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.ParerSlimicides}>
                          PARER SLIMICIDES
                        </NavLink>
                      </li> */}
                      {/* <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.SugarProcessing}>
                          SUGAR PROCESSING
                        </NavLink>
                      </li> */}
                      {/* <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.Pretreatment}>
                          PRETREATMENT
                        </NavLink>
                      </li>
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.HeatTreatmentSalts}>
                          HEAT TREATMENT SALTS
                        </NavLink>
                      </li>
                      <li
                        className="fs-6"
                        style={{
                          fontWeight: "400",
                        }}
                        onClick={handleToggleMenu}
                      >
                        <NavLink to={route.pages.product.Biotechnology}>
                          BIOTECHNOLOGY FOR ETP
                        </NavLink>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={route.services.SERVICES}
                      style={{
                        fontWeight: "400",
                      }}
                      onClick={handleToggleMenu}
                    >
                      Our Services
                    </Link>
                    {/* <ul className="sub-menu"></ul> */}
                  </li>
                  <li>
                    <NavLink
                      to={route.pages.knowledge.KNOWLEDGE}
                      style={{
                        fontWeight: "400",
                      }}
                        onClick={handleToggleMenu}
                    >
                      Knowledge
                    </NavLink>{" "}
                    {/* <ul className="sub-menu"></ul> */}
                  </li>
                  <li>
                    <NavLink
                      to={route.pages.chemical.CHEMICALS}
                      style={{
                        fontWeight: "400",
                      }}
                        onClick={handleToggleMenu}
                    >
                      Chemicals
                    </NavLink>{" "}
                    {/* <ul className="sub-menu"></ul> */}
                  </li>{" "}
                  <li>
                    <NavLink
                      to={route.pages.ABOUT}
                      style={{
                        fontWeight: "400",
                      }}
                        onClick={handleToggleMenu}
                    >
                      About us
                    </NavLink>{" "}
                    {/* <ul className="sub-menu"></ul> */}
                  </li>
                  <li
                    className="fs-6"
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    <NavLink
                      to={route.pages.CONTACTUS}
                      style={{
                        fontWeight: "400",
                      }}
                        onClick={handleToggleMenu}
                    >
                      Contact us
                    </NavLink>{" "}
                    {/* <ul className="sub-menu"></ul> */}
                  </li>
                  {/* <li className="fs-6"style={{
                  fontWeight: "400",
                }}>
                  <NavLink to={route.pages.contact.CONTACT1}>
                    Contact us
                  </NavLink>
                </li> */}
                  {/* <li
                    className="fs-6 "
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    <NavLink
                      to={route.pages.sitemap.SITEMAP}
                      style={{
                        fontWeight: "400",
                      }}
                        onClick={handleToggleMenu}
                    >
                      Site Map
                    </NavLink>
                     <ul className="sub-menu"></ul>
                  </li> */}
                </ul>
                {/* <div className="col-lg-2 text-end">
                  <a href="#search" className="site-search-btn">
                    <i className="fa fa-search fs-5" />
                  </a>
                </div>
                <div id="search" className=" bg-light">
                  <span className="close" />
                  <form
                    role="search"
                    id="searchform"
                    action="/search"
                    method="get"
                    className="radius-xl"
                  >
                    <div className="input-group">
                      <input
                        defaultValue=""
                        name="q"
                        type="search"
                        placeholder="Type to search"
                      />
                      <span className="input-group-btn">
                        <button type="button" className="search-btn">
                          <i className="fa fa-search" />
                        </button>
                      </span>
                    </div>
                  </form>
                </div> */}
              </div>

              {/* </div> */}
              {/* </div> */}
            </div>
          </div> 
          {/* <div className="col-lg-3 col-md-6 m-b50 bg-white bg-hower-shadow-secondary ms-5 mt-product ">
            <div className="mt-icon p-a60 text-center m-b10 ">
              <h5
                className="mt-tilte m-b20"
                style={{
                  fontWeight: "460",
                  fontSize: 25,
                }}
              >
                Our Products
              </h5>
              <p
                className="fs-6 justify-content-between"
                style={{
                  fontWeight: "400",
                }}
              >
                Comet Chemicals provides top-quality water treatment solutions,
                including boiler feed chemicals and cooling water treatments.
              </p>{" "}
              <span className="icon-arrow fs-6 site-text-primary">
                <a href={route.pages.product.PRODUCTS}>Learn More{' '}&nbsp; <b className="fa fa-arrow-right"></b></a>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-product  bg-white m-b50  ">
            <div className="mt-icon p-a60 text-center m-b25 ">
              <h5
                className="mt-tilte m-b25"
                style={{
                  fontWeight: "460",
                  fontSize: 25,
                }}
              >
                Our Services
              </h5>
              <p
                className="fs-6 justify-content-between"
                style={{
                  fontWeight: "400",
                }}
              >
                Comet Chemicals offers on-site water analysis and tailored
                treatment programs for efficient water management solutions.
              </p>{" "}
              <span className="icon-arrow fs-6 site-text-primary">
                <a href={route.services.SERVICES}>Learn More{' '}&nbsp; <b className="fa fa-arrow-right"></b></a>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 m-b50 bg-white mt-product ">
            <div className="mt-icon p-a60 text-center ">
              <h5
                className="mt-tilte m-b25"
                style={{
                  fontWeight: "460",
                  fontSize: 25,
                }}
              >
                Knowledge
              </h5>
              <p
                className="fs-6 justify-content-between"
                style={{
                  fontWeight: "400",
                }}
              >
                Discover essential insights on water composition, impurities,
                treatment methods, and effective water management strategies
                here.
              </p>
              <span className="fs-6 site-text-primary">
                <a href={route.pages.knowledge.KNOWLEDGE}>Learn More{' '}&nbsp; <b className="fa fa-arrow-right"></b></a>
              </span>
            </div>
          </div>*/}
          <div className="ms-5 "></div>
        </div>
        </div>
       
        {/* <div className="sticky-header main-bar-wraper">
        <div
          className="main-bar header-botton"
          style={{ backgroundColor: "#D3E3F3" }}
        >
          <div className="container">
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              type="button"
              className="navbar-toggler collapsed"
              onClick={handleToggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first" />
              <span className="icon-bar icon-bar-two" />
              <span className="icon-bar icon-bar-three" />
            </button>

            <div className="header-nav navbar-collapse collapse d-flex justify-content-start">
              <ul className="nav navbar-nav">
                <li className="active">
                  <NavLink
                    to={route.home.HOME1}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Home
                  </NavLink>
                </li>  <li>
                  <a
                    href="#"
                    style={{
                      fontWeight: "400",
                    }}
                  >
                   Our Products
                    <i className="fa fa-chevron-down radius" />
                  </a>
                  <ul className="sub-menu">
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.FeedChemicals}>
                        BOILER FEED CHEMICALS
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.WaterChemicals}>
                        COOLING WATER CHEMICALS
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.SideTreatments}>
                        FIRE SIDE TREATMENTS
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.RoAntiScalantKits}>
                        RO ANTI-SCALANT & DE-SCALANT
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.TestKits}>
                        TEST KITS
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.ParerSlimicides}>
                        PARER SLIMICIDES
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.SugarProcessing}>
                        SUGAR PROCESSING
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.Pretreatment}>
                        PRETREATMENT
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.HeatTreatmentSalts}>
                        HEAT TREATMENT SALTS
                      </NavLink>
                    </li>
                    <li
                      className="fs-6"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      <NavLink to={route.pages.product.Biotechnology}>
                        BIOTECHNOLOGY FOR ETP
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink
                    to={route.services.SERVICES}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                  Our  Services
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={route.pages.knowledge.KNOWLEDGE}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Knowledge
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={route.pages.chemical.CHEMICALS}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Chemicals
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink
                    to={route.pages.ABOUT}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    About us
                  </NavLink>
                </li>
                <li
                  className="fs-6"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  <NavLink
                    to={route.pages.CONTACTUS}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Contact us
                  </NavLink>
                </li>
                <li
                  className="fs-6"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  <NavLink
                    to={route.pages.sitemap.SITEMAP}
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    Site Map
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
        {/* </div> */}
      </header>
    </>
  );
}
export default NewSectionHeader;
